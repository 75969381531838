form{
    width:100%;
}

.MuiAppBar-root{
    box-shadow:initial!important;
}

.MuiTabs-scrollButtons.Mui-disabled {
    display: none;
    opacity: 0;
}

.MuiTabs-root {
    min-height: 36px !important;

    .MuiTab-root {
        min-height: 36px !important;
        padding: 0 16px;
        text-transform: none;

        &.Mui-selected {
            background: #fff;
            box-shadow: 0px 0 6px 0 rgb(0 0 0 / 20%);
        }

        &:has(.MuiIconButton-root) {
            padding-right: 0px;
        }

        .MuiIconButton-root {
            margin-left: 4px;

            svg {
                transform: scale(0.6);
            }
        }
    }
}
.MuiDataGrid-root {
    border: 0 !important;

    .MuiDataGrid-columnHeaders {
        background: #f9fafc;
    }

    .MuiDataGrid-cell, .MuiDataGrid-columnHeader {
        &:focus-within {
            outline: none !important;
        }
    }

    .MuiDataGrid-row {
        &:hover, &.Mui-hovered, &.Mui-selected {
            background-color: #634a7b30 !important;
        }
    }
}
.MuiPaper-root {
    box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px!important;
}
.MuiMenu-list {
    .Mui-selected {
        font-weight: bold;
    }
}
.MuiLink-root {
    color: #fc612a!important;
}